// Keep track of whether user is dragging or clicking
let dragging = false;
let swipeDirection, clientX, clientY = "";

document.addEventListener("DOMContentLoaded", function () {
	/**
	 *		- Determine if touch device is moving to prevent unwanted click/touch events
	 */
	document.addEventListener("touchmove", function (event) {
		dragging = true;

		var diffX, diffY = 0;
		diffX = event.touches[0].clientX - clientX;
		diffY = event.touches[0].clientY - clientY;

		if (Math.abs(diffX) > Math.abs(diffY)) {
			if (diffX < 0) {
				swipeDirection = "left";
			} else {
				swipeDirection = "right";
			}
		} else {
			if (diffY < 0) {
				swipeDirection = "up";
			} else {
				swipeDirection = "down";
			}
		}

		clientX = event.touches[0].clientX;
		clientY = event.touches[0].clientY;

	});
	document.addEventListener("touchstart", function (event) {
		dragging = false;
		swipeDirection = "";
		clientX = event.touches[0].clientX;
		clientY = event.touches[0].clientY;
	});
});